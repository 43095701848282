<script setup>
import { useCountries } from "@/composables/useCountries";
import { transl } from "~/composables/useTranslation";

const props = defineProps(["dialog", "country"]);
const emit = defineEmits(["update:dialog", "update:country"]);

const { matchCountryCode } = useCountries();

const dialog = computed({
  get: () => props.dialog,
  set: (val) => emit("update:dialog", val),
});

const searchText = ref("");
const codes = computed(() => matchCountryCode(searchText.value));

function selectCountry(country) {
  emit("update:country", country);
  dialog.value = false;
}

function openHandler() {
  searchText.value = "";
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    class="v-countries-dialog"
    @open="openHandler()"
      :options="{
      maxHeight: '50vh',
      style: {
        borderRadius: '20px',
      },
    }"
  >
    <div class="header pa-2">
      <v-text-field
        v-model="searchText"
        focused
        :label="transl('Texts:Search')"
        pattern="[\d\p{L}]*"
      >
        <template #prepend="{ hasFocus }">
          <!-- <font-awesome-icon
            icon="fa-solid fa-magnifying-glass"
            class="mr-3"
            :style="{
              color: hasFocus ? 'var(--icon)' : 'var(--icon)',
            }"
          /> -->
        </template>
      </v-text-field>
    </div>

    <div class="content">
      <div v-if="codes?.length" class="list-items">
        <div
          v-for="(item, i) in codes"
          :key="`code-${i}`"
          class="list-code"
          @click="selectCountry(item)"
        >
          <div class="item-country">
            {{ transl("Country:" + item.name) }}
          </div>
          <div class="d-flex justify-start mr-2 ml-1 item-code">+{{ item?.code }}</div>
        </div>
        <div class="mb-10"></div>
      </div>
      <div v-else class="d-flex justify-center pa-5 search">
        {{ transl("Texts:Nothing found") }}
      </div>
    </div>
  </v-dialog>
</template>

<style scoped>
.content {
  overflow-y: auto;
  overscroll-behavior-y: none;
}

.list-items {
}

.item-code {
  width: 50px;
  color: var(--text-color, #000);
}

.item-country {
  display: flex;
  flex: 1;
  margin-left: 20px;
  color: var(--text-color);
  user-select: none;
}

.list-code {
  cursor: pointer;
  display: flex;
  min-height: 50px;
  align-items: center;
  padding: 0 20px;
}

.list-code:hover {
  background-color: var(--button-hover);
}

.search {
  color: var(--text-color);
}
</style>
~/composables/useTranslation